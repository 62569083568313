import { GridColDef } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Link from "next/link";

import { IPermission } from "utils/permission";
import { getStatusText } from "utils/status";
import { getProjectTypeName } from "utils/project";
import { getFullName } from "utils/user";

// PAX-538 文字列の幅について、以下の列のみ固定値
// reference, type, status, schedules, createdAt, updatedAt
// 自動調整するならDataGridProが必要
export const Columns = (permission: IPermission): GridColDef[] => {
  const cols: GridColDef[] = [
    {
      field: "reference",
      headerName: "案件管理番号",
      sortable: true,
      width: 140,
      renderCell: (params: any) => (
        <div className={`relative`}>
          <Link href={`/projects/${params.id}`} target="_blank">
            <IconButton className="cursor-hover">
              <VisibilityIcon />
            </IconButton>
          </Link>
          {params.value}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "案件名",
      sortable: true,
      cellClassName: "text-center",
      flex: 3,
    },
    {
      field: "accountName",
      headerName: "取引先名",
      sortable: true,
      cellClassName: "text-center",
      flex: 1,
    },
    {
      field: "contactName",
      headerName: "取引先責任者",
      sortable: true,
      cellClassName: "text-center",
      flex: 1,
    },
    {
      field: "managerName",
      headerName: "案件管理者",
      sortable: true,
      cellClassName: "text-center",
      flex: 1,
    },
    {
      field: "quoterName",
      headerName: "見積担当者",
      sortable: true,
      cellClassName: "text-center",
      flex: 1,
    },
    {
      field: "operatorName",
      headerName: "業務担当者",
      sortable: true,
      cellClassName: "text-center",
      flex: 1,
    },
    {
      field: "user",
      headerName: "作成者",
      sortable: true,
      cellClassName: "text-center",
      flex: 1,
      type: "string",
    },
    {
      field: "alert",
      headerName: "注意事項",
      sortable: true,
      cellClassName: "text-center",
      flex: 2,
    },
    {
      field: "type",
      headerName: "案件タイプ",
      sortable: true,
      cellClassName: "text-center",
      width: 70,
      valueFormatter: (value) => getProjectTypeName(value),
      renderCell: (params) => <>{getProjectTypeName(params.value)}</>,
    },
    {
      field: "status",
      headerName: "案件フェーズ",
      sortable: true,
      cellClassName: "text-center",
      width: 70,
      valueFormatter: (value) => getStatusText(value),
      renderCell: (params) => <>{getStatusText(params.value)}</>,
    },
    {
      field: "schedules",
      headerName: "スケジュール数",
      sortable: true,
      cellClassName: "text-center",
      width: 50,
      valueFormatter: (value: { items: any[] }) => value.items.length,
      renderCell: (params) => <>{Object.keys(params.value.items).length}</>,
    },
    {
      field: "createdAt",
      headerName: "作成日",
      sortable: true,
      cellClassName: "text-center",
      width: 130,
    },
    {
      field: "updatedAt",
      headerName: "最終更新日",
      sortable: true,
      cellClassName: "text-center",
      width: 130,
    },
  ];

  if (permission.canWrite) {
    return cols;
  }

  return cols.filter((col) => col.field !== "editBtn");
};

export default Columns;
