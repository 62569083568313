import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { API } from "aws-amplify";
import LinearProgress from "@mui/material/LinearProgress";

import columns from "./Columns";

import { CustomError } from "utils/error";
import { Project } from "API";
import { dateStringToJaDateStr } from "utils/date";
import { dataGridToolbar } from "utils/toolbar";
import { useAuth } from "contexts/auth";
import { projectsByGroupIdForManager } from "graphql/queries";
import { SearchBox } from "components/SearchBox";
import { useAlerts } from "contexts/alerts";
import { ModelProjectConditionInput, ModelUserConditionInput } from "API";
import { getFullName } from "utils/user";

type CursorInfo = {
  nextToken: string;
  startIndex: number;
};

type FilterCondition = {
  projectFilter?: ModelProjectConditionInput[];
  userFilter?: ModelUserConditionInput[];
};

const dataToRows = (data: Project[]) => {
  if (!data) return [];
  const rows = data.map((d) => ({
    id: d.id,
    groupId: d.groupId,
    quoteId: d.quoteId,
    userId: d.userId,
    managerId: d.managerId,
    managerName: d.managerName,
    quoterId: d.quoterId,
    quoterName: d.quoterName,
    operatorId: d.operatorId,
    operatorName: d.operatorName,
    accountId: d.accountId,
    accountName: d.accountName,
    contactId: d.contactId,
    contactName: d.contactName,
    name: d.name,
    status: d.status,
    inquiryDate: d.inquiryDate,
    quotationDate: d.quotationDate,
    destinationName: d.destinationName,
    destination: d.destination,
    confidence: d.confidence,
    description: d.description,
    alert: d.alert,
    cancelled: d.cancelled,
    cancelReason: d.cancelReason,
    caseMarkNumImgs: d.caseMarkNumImgs,
    stockNumImgs: d.stockNumImgs,
    reference: d.reference,
    photoPhases: d.photoPhases,
    type: d.type,
    archived: d.archived,
    createdAt: d.createdAt ? dateStringToJaDateStr(d.createdAt) : null,
    updatedAt: d.updatedAt ? dateStringToJaDateStr(d.updatedAt) : null,
    account: d.account,
    contact: d.contact,
    schedules: d.schedules,
    user: getFullName(d.user),
    group: d.group,
  }));
  return rows;
};

const DEFAULT_PAGINATION_MODEL = {
  page: 0,
  pageSize: 100,
};

export function Table() {
  const { permissions, currentGroup, user } = useAuth();
  const { addAlert } = useAlerts();
  const [paginationModel, setPaginationModel] = useState(
    DEFAULT_PAGINATION_MODEL
  );
  // ページごとのnextTokenを管理
  const [cursorInfo, setCursorInfo] = useState<{ [key: string]: CursorInfo }>(
    {}
  );
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({});

  //PAX-538 デフォルトで表示しない列はここで指定する
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      managerName: false,
      quoterName: false,
      operatorName: false,
      user: false,
      alert: false,
      caseMarkNumImgs: false,
      stockNumImgs: false,
      archived: false,
      cancelled: false,
      cancelReason: false,
    });

  const generateCacheKey = (page: number) => {
    return JSON.stringify({
      query: "projectsByGroupIdForManager",
      groupId: currentGroup?.id,
      page,
      pageSize: paginationModel.pageSize,
      filterCondition: filterCondition,
    });
  };

  const hasNextPage =
    cursorInfo[generateCacheKey(paginationModel.page)]?.nextToken !== null;

  const fetchProjects = async () => {
    try {
      const res = await API.graphql({
        query: projectsByGroupIdForManager,
        variables: {
          groupId: currentGroup?.id,
          tenantId: user?.tenantId,
          filter: { ...filterCondition.projectFilter, archived: { ne: true } },
          userFilter: filterCondition.userFilter,
          limit: paginationModel.pageSize,
          nextToken:
            cursorInfo[generateCacheKey(paginationModel.page - 1)]?.nextToken ??
            null,
          startIndex:
            cursorInfo[generateCacheKey(paginationModel.page - 1)]
              ?.startIndex ?? 0,
          sortDirection: "DESC",
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const newNextToken = res.data.projectsByGroupIdForManager.nextToken;
      const newStartIndex = res.data.projectsByGroupIdForManager.startIndex;
      // 新しいトークンが存在する場合にnextTokensを更新
      setCursorInfo((prevCursorInfo) => {
        if (generateCacheKey(paginationModel.page) in cursorInfo) {
          return prevCursorInfo;
        }
        return {
          ...prevCursorInfo,
          [generateCacheKey(paginationModel.page)]: {
            nextToken: newNextToken,
            startIndex: newStartIndex,
          },
        };
      });
      return res.data.projectsByGroupIdForManager.items;
    } catch (err) {
      const customError = new CustomError(err, "get");
      if (customError instanceof Error)
        addAlert({ message: customError.message, severity: "error" });
    }
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    // 1ページあたりの表示数が変わるときはトークンとページをリセット
    if (newPaginationModel.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...newPaginationModel, page: 0 });
    } else {
      setPaginationModel(newPaginationModel);
    }
  };

  const resetPaginationModel = () => {
    setPaginationModel(DEFAULT_PAGINATION_MODEL);
  };

  const { data: projects, isLoading } = useQuery({
    queryKey: [
      "projectsByGroupIdForManager",
      currentGroup?.id,
      paginationModel.page,
      paginationModel.pageSize,
      filterCondition,
    ],
    queryFn: fetchProjects,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 6,
  });

  if (!projects && !isLoading) return null;
  return (
    <>
      <SearchBox
        setFilterCondition={setFilterCondition}
        resetPaginationModel={resetPaginationModel}
      />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <DataGrid
          key={hasNextPage ? "hasNextPage" : "noNextPage"}
          className="bg-white"
          slots={{ toolbar: dataGridToolbar }}
          slotProps={{
            loadingOverlay: {
              variant: "skeleton",
              noRowsVariant: "skeleton",
            },
          }}
          rows={dataToRows(projects)}
          columns={columns(permissions.MasterData)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          initialState={{
            pagination: { rowCount: -1 },
            density: "compact",
          }}
          // PAX-538 列選択をできないようにしている(1列だけ掴むとCSV出力などがうまくいかないため)
          isRowSelectable={() => false}
          // paginationの設定
          paginationMode="server"
          pageSizeOptions={[25, 50, 100]}
          paginationMeta={{ hasNextPage }}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
        />
      )}
    </>
  );
}

export default Table;
