import Table from "./Table";

function ProjectListView() {
  return (
    <div className="w-full p-2">
      <div className="flex justify-between"></div>
      <Table />
    </div>
  );
}

export default ProjectListView;
