import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

import { useGantt } from "contexts/gantt";
import { removeBeforeIdx } from "utils/gantt";

const options = [
  { value: "QUOTE", label: "見積" },
  { value: "ORDER", label: "受注" },
  { value: "STOCK", label: "入荷" },
  { value: "PACK", label: "梱包" },
  { value: "SHIP", label: "出荷" },
  { value: "INVOICE", label: "請求" },
  { value: "COMPLETE", label: "完了" },
  { value: "CANCEL", label: "失注" },
];

export default function ProjectFilter() {
  const [value, setValue] = useState<any[]>([]);
  const { projects, status, setStatus, keyword, setKeyword } = useGantt();

  useEffect(() => {
    setValue(options.filter((o) => status.includes(o.value)));
  }, [status]);

  return (
    <Autocomplete
      multiple
      sx={{ minWidth: 200 }}
      size="small"
      includeInputInList
      filterSelectedOptions
      getOptionLabel={(opt) => opt.label}
      options={options}
      clearOnBlur={false}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.label}
        </Box>
      )}
      onInputChange={(_, value) => {
        setKeyword(value);
        removeBeforeIdx(projects);
      }}
      onChange={(_, value) => {
        setStatus(value.map((v) => v.value) as any);
        removeBeforeIdx(projects);
      }}
      value={value}
      renderInput={(params) => (
        <TextField label="フェーズ" {...params} value={value} />
      )}
    />
  );
}
