import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import Link from "next/link";
import { Tooltip } from "@mui/material";

import { drawHolidayBorder, drawHolidayColor } from "utils/gantt";
import { getStatusText } from "utils/status";
import { Holiday } from "API";

export const dateColDef = (
  dates: string[],
  holidays: (Holiday | null)[] | undefined
) => {
  return dates.map((date) => ({
    field: date,
    headerName: (
      <div className="flex flex-col items-center">
        <div>{moment(date).format("ddd")}</div>
        <div>{moment(date).format("M/D")}</div>
      </div>
    ),
    headerClassName: `m-0 p-0 ${drawHolidayColor(
      date,
      holidays
    )} ${drawHolidayBorder(date, holidays)} `,
  }));
};

/**
 * スケジュールの表示カラム。表示制御ボタンのプロジェクト項目と見分けるためにfieldは"s."で始める。
 */
export const scheduleColDef = () => [
  {
    field: "s.name",
    headerName: "スケジュール名",
    headerClassName: "w-36",
    showColumnsMenu: true,
  },
  {
    field: "s.note",
    headerName: "スケジュールメモ",
    headerClassName: "w-32",
    cellClassName: "break-words",
    showColumnsMenu: true,
    editable: true,
    inputProps: {
      maxLength: 30,
    },
  },
];

export const projectColDef = () => [
  {
    field: "recentlyScheduleUpdated",
    headerName: "",
    headerClassName: "w-1",
    cellClassName: "cursor-hover hover:text-slate-800",
    showColumnsMenu: false,
    renderCell: (params: any) =>
      params.value && (
        <Tooltip
          title={`${new Date(params.value).toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Tokyo",
          })}にスケジュールが最終更新されています`}
          placement="top"
        >
          <div className="">
            <EditNotificationsIcon fontSize="small" />
          </div>
        </Tooltip>
      ),
  },
  {
    field: "name",
    headerName: "案件名",
    headerClassName: "w-48",
    cellClassName: "cursor-hover hover:text-slate-800",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>
        <Link href={`/projects/${params.id}`} target="_blank">
          <IconButton className="cursor-hover">
            <VisibilityIcon />
          </IconButton>
        </Link>
        {params.value}
      </div>
    ),
  },
  {
    field: "reference",
    headerName: "案件管理番号",
    headerClassName: "w-32 text-center",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative text-center`}>{params.value}</div>
    ),
  },
  {
    field: "status",
    headerName: "フェーズ",
    headerClassName: "w-24 text-center",
    cellClassName: "text-center",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative text-center`}>
        {getStatusText(params.value)}
      </div>
    ),
  },
  {
    field: "accountName",
    headerName: "取引先名",
    headerClassName: "w-44",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>{params.value}</div>
    ),
  },
  {
    field: "contactName",
    headerName: "取引先責任者",
    headerClassName: "w-32",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>{params.value}</div>
    ),
  },
  {
    field: "userName",
    headerName: "作成者",
    headerClassName: "w-32",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>{params.value}</div>
    ),
  },
  {
    field: "managerName",
    headerName: "案件担当者",
    headerClassName: "w-32",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>{params.value}</div>
    ),
  },
  {
    field: "operatorName",
    headerName: "業務担当者",
    headerClassName: "w-32",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>{params.value}</div>
    ),
  },
  {
    field: "quoterName",
    headerName: "見積担当者",
    headerClassName: "w-32",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative`}>{params.value}</div>
    ),
  },
  {
    field: "createdAt",
    headerName: "作成日",
    headerClassName: "w-28 text-center",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative text-center`}>
        {new Date(params.value).toLocaleDateString("ja-JP", {
          month: "numeric",
          day: "numeric",
        })}
      </div>
    ),
  },
  {
    field: "updatedAt",
    headerName: "最終更新日",
    headerClassName: "w-28 text-center",
    showColumnsMenu: true,
    renderCell: (params: any) => (
      <div className={`relative text-center`}>
        {new Date(params.value).toLocaleDateString("ja-JP", {
          month: "numeric",
          day: "numeric",
        })}
      </div>
    ),
  },
];
