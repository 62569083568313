import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useGantt } from "contexts/gantt";
import { removeBeforeIdx } from "utils/gantt";

const options = [
  { id: "見積", name: "見積" },
  { id: "受注", name: "受注" },
  { id: "入荷", name: "入荷" },
  { id: "梱包", name: "梱包" },
  { id: "出荷", name: "出荷" },
  { id: "請求", name: "請求" },
  { id: "完了", name: "完了" },
  { id: "失注", name: "失注" },
];

export default function ProjectFilter() {
  const { projects, keyword, setKeyword } = useGantt();
  return (
    <Autocomplete
      sx={{ minWidth: 140 }}
      size="small"
      includeInputInList
      filterSelectedOptions
      getOptionLabel={(opt) => opt.name}
      options={options}
      clearOnBlur={false}
      inputValue={keyword || ""}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      )}
      onInputChange={(_, value) => {
        setKeyword(value);
        removeBeforeIdx(projects);
      }}
      onChange={(_, value) => value && setKeyword(value?.name as string)}
      renderInput={(params) => (
        <TextField
          label="検索"
          placeholder="案件名、得意様名"
          value={keyword || ""}
          {...params}
        />
      )}
    />
  );
}
