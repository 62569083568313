import TableCell from "@mui/material/TableCell";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";

import { useGantt } from "contexts/gantt";
import {
  drawHolidayBorder,
  drawHolidayColor,
  getScheduleDates,
} from "utils/gantt";
import { DraggableType } from "utils/gantt/constant";
import { getPhase, getUpdatedSchedule } from "utils/gantt/schedule";
import { Project, Schedule } from "API";
import { useGroups } from "contexts/groups";
import { useAuth } from "contexts/auth";
import { useAlerts } from "contexts/alerts";

function EmptyCell({
  date,
  color,
  className,
  onClick,
  cellRef,
  holidays,
}: any) {
  return (
    <TableCell
      onClick={onClick}
      className={`${className} ${drawHolidayColor(
        date,
        holidays
      )} ${drawHolidayBorder(date, holidays)} h-14`}
      style={color ? { backgroundColor: color } : {}}
      ref={cellRef !== undefined ? cellRef : null}
    />
  );
}

function getCellStyle(data: any) {
  return `bg-[${data.color}] text-white text-gray-100 h-10`;
}

type DragItem = {
  fromDate: string;
};

export default function GanttCell({
  row,
  date,
  focus,
  noScheduleClassName,
  draggableId,
  setSchedules,
}: any) {
  const { projects: ganttProjects, dates } = useGantt();
  const { groups } = useGroups();
  const { currentGroup, permissions } = useAuth();
  // rowとganttProjectsの最新の状態を保持するためのRefを作成します
  const rowRef = useRef(row);
  const refGanttProjects = useRef(ganttProjects);
  const { addAlert } = useAlerts();

  // rowとganttProjectsが更新されるたびにそれぞれのRefを更新します
  rowRef.current = row;
  refGanttProjects.current = ganttProjects;
  // 休日カレンダー
  const holidayCalendar = groups?.find((group) => group.id === currentGroup?.id)
    ?.holidays?.items;

  const [, drag] = useDrag<DragItem, any, any>(
    () => ({
      type: DraggableType.GANTT_CELL(draggableId),
      item: { fromDate: date }, // drag元の日付
    }),
    []
  );

  const [, drop] = useDrop<DragItem, any, any>(
    () => ({
      accept: DraggableType.GANTT_CELL(draggableId),
      drop: async (item) => {
        try {
          // scheduleを更新する
          const updatedSchedule = await getUpdatedSchedule(
            item.fromDate,
            date,
            rowRef.current // rowRef.currentは更新されたrowの値を保持している
          );
          setSchedules((prev: [Schedule]) => {
            const updatedSchedules = [...prev];
            const index = updatedSchedules.findIndex(
              (schedule) => schedule.id === updatedSchedule.id
            );
            updatedSchedules[index] = {
              ...updatedSchedule,
              ...getScheduleDates(updatedSchedule, dates),
            };
            return updatedSchedules;
          });
          // ganttの中で何番目にあるかをidxで取得、最新のganttProjectsを参照する
          const idx = refGanttProjects.current.findIndex((project: Project) => {
            return project.id === updatedSchedule.projectId;
          });
          // ganttProjectsの中でtargetProjectと同じidを持つものを更新する
          const targetProject = ganttProjects.find(
            (project: Project) => project.id === updatedSchedule.projectId
          ) as Project & { beforeIdx: number };
          if (!targetProject || !targetProject.schedules) return;
          // schedulesの中でtargetScheduleと同じidを持つものを更新する
          targetProject.schedules.items = targetProject.schedules.items.map(
            (schedule: Schedule | null) => {
              if (schedule === null) return null;
              if (schedule.id === updatedSchedule.id) {
                return updatedSchedule;
              }
              return schedule;
            }
          );
          // ドラッグ＆ドロップで変更されたことをprojectレベルで持たせる
          targetProject.beforeIdx = idx;
          addAlert({
            message: "スケジュールを更新しました",
            severity: "success",
          });
        } catch (e) {}
        return;
      },
      // メモ化されてほしくない値は依存配列に追加すること
      // 非同期処理が完了していない状態でメモ化すると古い値を参照するため状態管理がおかしくなる
    }),
    [ganttProjects, setSchedules]
  );

  // dragとdropを同じコンポーネントに反映するための
  const draggableAndDroppable = (el: any) => {
    drag(el);
    drop(el);
  };

  if (!row)
    return (
      <EmptyCell
        date={date}
        onClick={focus}
        className={noScheduleClassName}
        cellRef={drop} // 予定がないところはdropのみ
        holidays={holidayCalendar}
      />
    );

  const data = row[date];

  // typeが空の時はdragできない
  if (!data || data.type === "")
    return (
      <EmptyCell
        date={date}
        onClick={focus}
        color={data?.color}
        cellRef={drop} // 予定がないところはdropのみ
        holidays={holidayCalendar}
      />
    );

  // rowから各フェーズの日にちを持ってくる
  // data.typeが空ではないけど、Dataは持っていない場合(= フェーズの途中)
  if (!data.hasData) {
    // そのフェーズの最後の日付(=stock以外のフェーズの前の日)の場合は、ドラッグできる
    // fromDateからどのフェーズか確認
    const phase = getPhase(row, date);
    if (phase === "other") return <></>;
    return (
      <TableCell
        onClick={focus}
        className={`${drawHolidayBorder(date, holidayCalendar)} h-14`}
        style={data.color ? { backgroundColor: data.color, opacity: 0.7 } : {}}
        ref={drop} // 予定があっても予定の途中はdropのみ
      />
    );
  }

  // こいつは終点のはずなのでドラッグできる
  return (
    <TableCell
      className={`${getCellStyle(data)} ${drawHolidayBorder(
        date,
        holidayCalendar
      )}`}
      style={{ backgroundColor: data.color, color: "white" }}
      ref={permissions.Project.canWrite ? draggableAndDroppable : drop} // 予定がある場合はdrag and dropできる
    >
      {dates.length > 14 ? data.initial : `${data.text}`}
    </TableCell>
  );
}
